import Vue from 'vue';
export default class UploadEmailService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }
    listarContatos(email) {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        return this._http.get(url + `/email/api/contacts/`, {
            params: {
                apresentante: this._store.getters.cdApresentante,
                email_id: email.id
            }
        });
    }
    salvarContato(data) {
        var response = null;
        let url = process.env.VUE_APP_URL_BACK_OFFICE;
        url = url + '/email/api/contacts/';
        if (data.id) {
            let path = data.id + '/';
            response = Vue.http.put(url + path, data);
        } else {
            response = Vue.http.post(url, data);
        }
        return response;
    }
}
