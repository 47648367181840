<template>
    <div id="margin-dialog-body">
        <v-card class="mb-3">
            <v-container justify="center">
                <v-row align="center">
                    <v-col sm="2">
                        <p class="font-weight-bold ml-3">
                            Fazer Upload de Arquivo Excel com os Telefones.
                        </p>
                    </v-col>
                    <v-col sm="6">
                        <v-file-input
                            class="mb-n7"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .xlsm"
                            label="Selecione o arquivo (.xls/.xlsx/ .xlsm)"
                            show-size
                            :loading="loadingUpload"
                            :disabled="desabilitaBoxEnvio"
                            chips
                            outlined
                            ref="myFileInput"
                            id="fileInput"
                            name="file"
                            @change="uploadFileReference"
                        ></v-file-input>
                    </v-col>
                    <v-col sm="4" align="center">
                        <v-btn
                            color="primary white--text"
                            large
                            :loading="loadingUpload"
                            :disabled="uploadOk"
                            @click="upload"
                        >
                            Enviar
                            <v-icon class="ml-2">cloud_upload</v-icon>
                        </v-btn>
                        <v-btn
                            color="primary white--text"
                            class="mx-3"
                            large
                            :href="linkModelo"
                        >
                            template
                            <v-icon class="ml-2">cloud_download</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" sm="4" md="12" justify-center
                        ><p class="primary--text display-1 text-center">
                            Status de Upload:
                        </p></v-col
                    >
                    <v-col cols="6" sm="4" md="4">
                        <v-btn block large outlined color="primary">
                            A Enviar: {{ customerCountRead }}
                        </v-btn>
                    </v-col>
                    <v-col cols="6" sm="4" md="4">
                        <v-btn block large color="success">
                            Enviados:{{ customerCount }}
                        </v-btn>
                    </v-col>
                    <v-col cols="6" sm="4" md="4">
                        <v-btn block large outlined color="error">
                            Erro: {{ customerCountError }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-col cols="6" sm="4" md="12" justify-center
            ><p class="title-1 text-center">Resultados:</p></v-col
        >
        <v-data-table
            :headers="headers"
            :items="contacts"
            :options.sync="options"
            :loading="loading"
            :footer-props="{
                itemsPerPageOptions: [50, 100, 500]
            }"
            loading-text="Atualizando..."
            no-data-text="Preencha os filtros e clique em filtrar..."
            no-results-text="Nenhum título encontrado..."
            class="elevation-1"
        >
            <template v-slot:[`item.enviado`]="{ item }">
                <v-icon
                    :style="{ color: item.enviado != '' ? 'green' : 'red' }"
                    >{{ getBolean(item.enviado) }}</v-icon
                >
            </template>
        </v-data-table>
    </div>
</template>

<script>
//import * as Formatter from '@/helpers/Formatter';
import Vue from 'vue';
import { required, email, max } from 'vee-validate/dist/rules';
import { extend, setInteractionMode } from 'vee-validate';
import uploadEmailService from '@/services/UploadEmailService';
import XLSX from 'xlsx';

setInteractionMode('eager');

extend('required', {
    ...required,
    message: '{_field_} can not be empty'
});

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters'
});

extend('email', {
    ...email,
    message: 'Email must be valid'
});

export default {
    name: 'DetalheUploadEmail',
    props: {
        detalheEmail: {
            type: Object,
            required: true
        },
        showActionSalvar: Boolean
    },
    data: () => ({
        maxFileSize: 60000000,
        rules: [
            value =>
                !value ||
                (this.checkfile(value) && this.checkSize(value)) ||
                'O arquivo deve possuir menos de 60 MB e ser do tipo .xls/.xlsx!'
        ],
        wordsRules: [v => v.trim().split(' ').length <= 5 || 'Max 5 words'],
        value: 'Esse é um EMail exemplo, onde informa os detalhes da oferta',
        errorMessages: null,
        linkModelo: '/Recuperi-SMS.xlsx',
        loading: false,
        loadingUpload: false,
        uploadOk: false,
        mensagemAlerta: false,
        desabilitaBoxEnvio: false,
        file: null,
        customerCount: 0,
        customerCountError: 0,
        customerCountRead: 0,
        contacts: [],
        newContacts: [],
        options: {},
        headers: [
            {
                text: 'Id',
                sortable: false,
                value: 'id'
            },
            {
                text: 'Nome',
                sortable: false,
                value: 'name'
            },
            {
                text: 'Telefone',
                align: 'left',
                sortable: false,
                value: 'phone'
            },
            {
                text: 'Valor do Título',
                sortable: false,
                value: 'document_value'
            },
            {
                text: 'Valor Protestado',
                sortable: false,
                value: 'value'
            },
            {
                text: 'Enviados',
                sortable: false,
                value: 'enviado'
            }
        ]
    }),
    methods: {
        cleanFields() {
            this.$refs.myFileInput.reset();
        },
        checkChar() {
            if (this.email.mensagem && this.email.mensagem <= 100) {
                return true;
            } else {
                return false;
            }
        },
        salvar() {
            this.$emit('actionSalvar');
        },
        submit() {
            this.$refs.observer.validate();
        },
        checkfile(sender) {
            var validExts = new Array(
                '.xlsm',
                '.xlsx',
                '.xls',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel'
            );
            var fileExt = sender.name;
            fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
            if (validExts.includes(fileExt)) {
                return true;
            } else return false;
        },
        checkSize(value) {
            return value.size < this.maxFileSize;
        },
        buscarContatos() {
            let uploadService = new uploadEmailService(Vue.http, this.$store);
            uploadService.listarContatos(this.email).then(
                response => {
                    this.contacts = response.data.results;
                },
                error => {
                    console.log(error);
                }
            );
        },
        uploadFileReference(e) {
            if (!e) return null;
            this.file = e;
            this.uploadOk = false;
            const reader = new FileReader();
            var vm = this;
            reader.onload = function(e) {
                var data = e.target.result;
                var workbook = XLSX.read(data, { type: 'binary' });
                let sheetName = workbook.SheetNames[0];
                let worksheet = workbook.Sheets[sheetName];
                let rowObject = XLSX.utils.sheet_to_row_object_array(worksheet);
                const finalJsonData = JSON.stringify(rowObject, undefined, 4);
                let rows = JSON.parse(finalJsonData);
                rows.splice(0, 1);
                vm.customerCountRead = rows.length;
                vm.newContacts = rows;
                this.customerCountError = 0;
                this.customerCount = 0;
            };
            reader.readAsBinaryString(this.file);
        },
        getBolean(valor) {
            if (!valor || valor === true) {
                return 'done';
            } else {
                return 'close';
            }
        },
        upload() {
            if (!this.file) {
                this.tipoMensagem = `error`;
                this.mensagem = 'Selecione um arquivo!!!';
                return;
            } else if (!this.checkfile(this.file)) {
                this.tipoMensagem = `error`;
                this.mensagem = 'O arquivo não é do tipo .xls/.xlsx/.xlsm';
            } else if (this.file.size > this.maxFileSize) {
                this.tipoMensagem = `error`;
                this.mensagem = 'O arquivo possui mais buscarHistoricode 60 MB';
            } else {
                let uploadService = new uploadEmailService(
                    Vue.http,
                    this.$store
                );
                const email = this.email.id;
                for (var i in this.newContacts) {
                    var row = this.newContacts[i];
                    row.email = email;
                    if (row.document_value)
                        row.document_value = row.document_value.toFixed(2);
                    if (row.value) row.value = row.value.toFixed(2);
                    row.phone = row.phone.toString().replace(/\D/g, '');
                    uploadService.salvarContato(row).then(
                        response => {
                            let data = response.data;
                            data.enviado = true;
                            let contactsResponse = [];
                            contactsResponse.push(data);
                            this.contacts = contactsResponse.concat(
                                this.contacts
                            );
                            this.customerCount += 1;
                        },
                        error => {
                            console.log(error);
                            row.enviado = false;
                            let contactsResponse = [];
                            contactsResponse.push(row);
                            this.contacts = contactsResponse.concat(
                                this.contacts
                            );
                            this.customerCountError += 1;
                        }
                    );
                }
            }
        }
    },
    mounted() {
        this.email = this.detalheEmail;
        this.buscarContatos();
    },
    computed: {
        getNomeCliente() {
            return 'Cliente Exemplo';
        }
    },
    watch: {
        detalheEmail() {
            this.email = this.detalheEmail;
            this.buscarContatos();
            this.cleanFields();
        }
    }
};
</script>

<style scoped>
#margin-dialog-body {
    margin-top: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    margin-left: 0px;
}
</style>
