export default class Email {
    constructor() {
        this.campaign_name = null;
        this.email_subject = null;
        this.destination_name = null;
        this.destination_url = null;
        this.email_template_id = null;
        this.time_stamp = false;
        this.send_sms = false;
        this.sms_template_id = '';
        this.send_whatsapp = false;
        this.whatsapp_template_id = '';
        this.contacts = [];
    }
}
